var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('span', {
    staticClass: "font-weight-bold text-black ml-2"
  }, [_vm._v(_vm._s(_vm.market))])]);
}
var staticRenderFns = []

export { render, staticRenderFns }