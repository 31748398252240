<template>
  <div>
    <span class="font-weight-bold text-black ml-2">{{ market }}</span>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "market-theme",
  data() {
    return {};
  },
  computed: {
    ...mapState({
      market: (state) => state.theme.market,
    }),
  },

  async mounted() {},
};
</script>
